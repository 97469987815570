<template>
  <div>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 order-lg-1 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetWorkedHours v-if="initWidgetWorkedHoursChartDataLoaded"
              :chartData="widgetWorkedHoursChartData"
            ></WidgetWorkedHours>
          </template>
        </KTPortlet>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 order-lg-2 order-xl-2">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetLouHandler v-if="initWidgetLouHandlerChartDataLoaded"
              :chartData="widgetLouHandlerChartData"
            ></WidgetLouHandler>
          </template>
        </KTPortlet>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 order-lg-3 order-xl-3">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetRecentNominations v-if="initWidgetRecentNominationsChartDataLoaded"
              :chartData="widgetRecentNominationsChartData"
            ></WidgetRecentNominations>
          </template>
        </KTPortlet>
      </div>

    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 order-lg-12 order-xl-12">
        <!--Begin::Recent Activities-->
        <KTPortlet 
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind:title="'Dormant WO'"
        >
          <template v-slot:body>
            <!--Begin::Timeline 3 -->
            <WidgetDormantWO v-if="initWidgetDormantWOChartDataLoaded"
              :chartData="widgetDormantWOChartData"
            ></WidgetDormantWO>
            <!--End::Timeline 3 -->
          </template>
        </KTPortlet>
        <!--End::Recent Activities-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import ContextMenu1 from "@/views/partials/widgets/ContextMenu1.vue";
//import Widget5 from "@/views/partials/widgets/Widget5.vue";
import WidgetWorkedHours from "@/views/partials/widgets/Widget_WorkedHoursHandler.vue";
import WidgetLouHandler from "@/views/partials/widgets/Widget_LouHandler.vue";
import WidgetRecentNominations from "@/views/partials/widgets/Widget_RecentNominations.vue";
//import Widget17 from "@/views/partials/widgets/Widget17.vue";
//import Widget20_1 from "@/views/partials/widgets/Widget20_Inbound.vue";
//import Widget20_2 from "@/views/partials/widgets/Widget20_Outbound.vue";
//import WidgetRecentActivities from "@/views/partials/widgets/Widget_RecentActivities.vue";
import WidgetDormantWO from "@/views/partials/widgets/Widget_DormantWO.vue";

/**
 * Sample widgets data source
 */
//import widget_recent_activities from "@/common/mock/widget-recent-activities.json";
import widget5 from "@/common/mock/widget-5.json";
import genericService from "./../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    //ContextMenu1,
    //Widget5,
    WidgetWorkedHours,
    WidgetLouHandler,
    WidgetRecentNominations,
    //Widget17,
    //Widget20_1,
    //Widget20_2,
    //WidgetRecentActivities,
    WidgetDormantWO
  },
  data() {
    return {
      widget5: widget5,
      tabIndex: 0,
      tabIndex2: 0,

      widgetWorkedHoursChartData: {},
      widgetLouHandlerChartData: {},
      widgetRecentNominationsChartData: {},
      widgetDormantWOChartData: {},

      initWidgetWorkedHoursChartDataLoaded: false,
      initWidgetLouHandlerChartDataLoaded: false,
      initWidgetRecentNominationsChartDataLoaded: false,
      initWidgetDormantWOChartDataLoaded: false

    };
  },

  created() {
    this.initWidgetWorkedHoursChartData();
    this.initWidgetLouHandlerChartData();
    this.initWidgetRecentNominationsChartData();
    this.initWidgetDormantWOChartData();

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard Handler" }]);
  },

  computed: {
    ...mapGetters([ 'currentUser' ])
  },

  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    initWidgetWorkedHoursChartData() {
      var _self = this; 

      genericService.getReport("handler_worked_hours/" + _self.currentUser.id, function(reportData){
        if(reportData != undefined) {
          _self.widgetWorkedHoursChartData = reportData;
          _self.initWidgetWorkedHoursChartDataLoaded = true
        }
      });
    },

    initWidgetLouHandlerChartData() {
      var _self = this; 

      genericService.getReport("handler_pending_lou/" + _self.currentUser.id, function(reportData){
        if(reportData != undefined) {
          _self.widgetLouHandlerChartData = reportData;
          _self.initWidgetLouHandlerChartDataLoaded = true
        }
      });
    },

    initWidgetRecentNominationsChartData() {
      var _self = this; 

      genericService.getReport("handler_recent_nominations/" + _self.currentUser.id, function(reportData){
        if(reportData != undefined) {
          _self.widgetRecentNominationsChartData = reportData;
          _self.initWidgetRecentNominationsChartDataLoaded = true
        }
      });
    },

    initWidgetDormantWOChartData() {
      var _self = this; 
      genericService.getReport("handler_dormant_wo/" + _self.currentUser.id, function(reportData){
        if(reportData != undefined) {
          _self.widgetDormantWOChartData = reportData;
          _self.initWidgetDormantWOChartDataLoaded = true
        }
      });
    },

  }
};
</script>
